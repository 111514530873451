/* body {
  background-color: #f5f8f9;
} */

* {
  scroll-behavior: smooth;
}

iframe {
  display: none;
}
body::-webkit-scrollbar {
  display: none;
}
.url-content .ql-editor {
  height: 10em;
}
.email-content .ql-editor {
  height: 20em;
}
.email-preview p {
  margin-bottom: 0.5rem;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
:root {
  --bs-body-font-size: 14px !important;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.main-content {
  display: flex;
  flex: 1 1 100%;
  height: calc(100vh - 65px);
  max-width: calc(100% - 60px);
  overflow: auto;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  border-left: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 15px 0px 0px 0px;
  background-color: var(--bs-body-bg);
}

/* Sidebar-------------------------------------- */
.sidebar {
  flex: 1 1 100%;
  height: calc(100vh - 65px);
  max-width: 60px;
  padding-top: 18px;
  transition: all 0.4s ease-in-out;
  overflow: auto;
}
.sidebar:hover {
  max-width: 230px;
}
.item-wrapper {
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 50px;
  width: 225px;
  border-radius: 0px 10px 10px 0px;
  gap: 8px;
  padding-left: 5px;
}
.item-wrapper:hover {
  background-color: #dff0ff;
}

.item-wrapper.nav-link.active {
  background-color: #dff0ff;
}

/* Scrollbar------------------------------------- */
.main-content::-webkit-scrollbar {
  width: 8px;
}
.main-content::-webkit-scrollbar-track {
  display: none;
}
.main-content::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}
.sidebar::-webkit-scrollbar {
  display: none;
}

/* Sub-Sidebar--------------------------------- */
.sub-sidebar {
  position: relative;
  left: -16px;
  flex: 1 1 100%;
  max-width: 210px;
  border-right: 1px solid rgba(0, 0, 0, 0.15);
  background-color: #fdfdfd;
}
.inner-sub-sidebar {
  position: fixed;
  width: 209px;
}

/* Profile------------------------------------------- */
.search-input,
.search-input:focus,
.search-input::placeholder {
  border: none;
  outline: none;
  color: rgba(0, 0, 0, 0.25);
  font-size: 16px;
  font-weight: 500;
}
.custom.dropdown-toggle::after {
  display: none;
}
.dropdown-menu[data-bs-popper] {
  right: 0;
  left: auto;
  min-width: fit-content;
}

/* Tabs ------------------------------------*/
.tab-heading {
  height: 35px;
  font-size: 15px;
  color: #222;
  display: flex;
  align-items: center;
  font-weight: 700;
}
.tab-container {
  display: flex;
  flex-direction: column;
}
.tab-item {
  display: flex;
  align-items: center;
  text-align: start;
  color: #222;
  font-size: 15px;
  font-weight: 400;
  height: 35px;
}
.tab-item.active {
  color: #222 !important;
  font-weight: 600;
  background-color: #dff0ff !important;
  border-radius: 0px;
}

/* Outlet---------------------------------------------- */
.outlet-wrapper {
  flex: 1 1 100%;
  max-width: calc(100% - 210px);
}

.input-group > .btn {
  border-color: #ced4db;
}

/* Slide out animation to remove element ----------------------------------------------*/
.slide-out {
  position: relative;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-name: slideOut;
}

@keyframes slideOut {
  0% {
    left: 0;
  }
  75% {
    left: 2000px;
    max-height: 10rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-width: 1px;
    overflow: hidden;
  }
  100% {
    left: 2000px;
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
    border-width: 0;
    overflow: hidden;
  }
}
